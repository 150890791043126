<template>
  <Details
    title="bundle"
    resource="bundles"
    :base-path="basePath"
    data-provider="$authDataProvider"
  >
    <template v-slot:side-panel>
      <DetailsSidePanel>
        <template v-slot:heading>bundle details</template>
        <template v-slot:text>Add the basic information about the bundle, including required fields.</template>
      </DetailsSidePanel>
    </template>
    <template v-slot:default="detailsProps">
      <BundleForm
        :initial-values="initialValues"
        :on-submit="detailsProps.handleSubmit"
        :loading="detailsProps.loading"
        @close="detailsProps.redirectToList"
      />
    </template>
  </Details>
</template>

<script>
  import ModalNavigation from "@/mixins/ModalNavigation";
  import Details from "@/components/auth/details/Details";
  import DetailsSidePanel from "@/components/auth/details/DetailsSidePanel";
  import BundleForm from "@/views/auth/applications/bundles/BundleForm";

  export default {
    name: "BundleCreate",
    components: {
      BundleForm,
      Details,
      DetailsSidePanel,
    },
    mixins: [ModalNavigation],
    computed: {
      basePath: function () {
        return this.$route.name.replace('create', 'index');
      },
      initialValues() {
        return {
          type: "SUITE",
          mappedRoles: {},
        }
      },
    },
  }
</script>

<style scoped>

</style>
